// Package Imports
import crypto from 'crypto'

// Other Imports
import { CIPHER_ENCRYPTION_KEY, CIPHER_RSA_PUBLIC_KEY } from './constants'

const ALGORITHM = 'aes-256-cbc'
const ENCODING = 'hex'
const IV_LENGTH = 16
const KEY = CIPHER_ENCRYPTION_KEY!
const publicKey = CIPHER_RSA_PUBLIC_KEY!

export const getSHA256Hash = (text: string) => {
  if (!text) {
    return ''
  }
  return crypto.createHash('sha256').update(text).digest('hex')
}

export const encrypt = (data: string) => {
  const iv = crypto.randomBytes(IV_LENGTH)
  const cipher = crypto.createCipheriv(ALGORITHM, new Buffer(KEY), iv)
  return Buffer.concat([cipher.update(data), cipher.final(), iv]).toString(
    ENCODING
  )
}

export const decrypt = (data: string) => {
  const binaryData = new Buffer(data, ENCODING)
  const iv = binaryData.slice(-IV_LENGTH)
  const encryptedData = binaryData.slice(0, binaryData.length - IV_LENGTH)
  const decipher = crypto.createDecipheriv(ALGORITHM, new Buffer(KEY), iv)

  return Buffer.concat([
    decipher.update(encryptedData),
    decipher.final(),
  ]).toString()
}

// using descryption algorithm = RSA/ECB/OAEPWithSHA-1AndMFG1Padding
export const getEncryptedNumber = (data: any) => {
  if(!data) return ''
  const bufferData = Buffer.from(data, 'utf8'); // Convert data to buffer (binary format)
   const uint8ArrayData = new Uint8Array(bufferData.buffer);
   // Use the crypto.publicEncrypt method to encrypt the data
   const encryptedData = crypto.publicEncrypt(publicKey, uint8ArrayData);
  // Return the encrypted data in base64 encoding (commonly used for binary data transfer)
  return encryptedData.toString('base64');
}

// export const getSHA256Hash = async (input: string) => {
//   // Use a JavaScript implementation if SubtleCrypto is not available
//   const textAsBuffer = new TextEncoder().encode(input)
//   if (typeof window === 'undefined') {
//     return ''
//   }
//   const hashBuffer = await window?.crypto?.subtle?.digest(
//     'SHA-256',
//     textAsBuffer
//   )
//   const hashArray = Array.from(new Uint8Array(hashBuffer))
//   const hash = hashArray
//     .map((item) => item.toString(16).padStart(2, '0'))
//     .join('')
//   return hash
// }

// export const encrypt = async (data: string): Promise<string> => {
//   if (typeof window === 'undefined') return ''
//     const key = await window.crypto.subtle.generateKey(
//       { name: 'AES-GCM', length: 256 },
//       true,
//       ['encrypt', 'decrypt']
//     )
//     const rawData = JSON.stringify(data)
//     const encodedData = new TextEncoder().encode(rawData)

//     const encryptedData = await crypto.subtle.encrypt(
//       { name: 'AES-GCM', iv: new Uint8Array(12) },
//       key,
//       encodedData
//     )

//     const encryptedArray = Array.from(new Uint8Array(encryptedData))
//     const encryptedString = encryptedArray
//       .map((byte) => String.fromCharCode(byte))
//       .join('')
//     return btoa(encryptedString)
// }

// export const decrypt = async (data: string): Promise<any> => {
//   if (typeof window === 'undefined') return '{}'
//   const key = await window.crypto.subtle.generateKey(
//     { name: 'AES-GCM', length: 256 },
//     true,
//     ['encrypt', 'decrypt']
//   )
//   const encryptedArray = new Uint8Array(
//     atob(data)
//       .split('')
//       .map((char) => char.charCodeAt(0))
//   )

//   const decryptedData = await crypto.subtle.decrypt(
//     { name: 'AES-GCM', iv: new Uint8Array(12) },
//     key,
//     encryptedArray
//   )

//   const decryptedString = new TextDecoder().decode(decryptedData)
//   return JSON.parse(decryptedString)
// }
